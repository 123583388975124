/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import {
  Html,
  PageHeading,
  TextContent,
} from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  Section,
  PageGrid,
  PageGridItem,
  useThemeProps,
  Typography,
  ListStack,
  PageSection,
  Box,
} from "@wsui/base";

export default function FractionsTemplate(props) {
  const { textContent, sortingGuides } = usePageContext();
  return (
    <article>
      <PageSection background="transparent">
        <PageGrid>
          <PageGridItem maxColspan={7}>
            <PageHeading marginAfter />
            <Section>
              <TextContent
                css={css`
                  margin-top: 24px;
                `}
              >
                {textContent && <Html>{textContent}</Html>}
              </TextContent>
              {sortingGuides && (
                <Box
                  css={css`
                    margin-top: 24px;
                  `}
                >
                  <Typography
                    variant="h3"
                    css={css`
                      margin-bottom: 12px;
                    `}
                  >
                    Exempel på avfall som ingår
                  </Typography>
                  <ListStack
                    css={css`
                      list-style: disc;
                      padding-left: 40px;
                    `}
                  >
                    {sortingGuides.nodes
                      .sort((first, second) =>
                        first.title.localeCompare(second.title),
                      )
                      .map((item, index) => (
                        <Typography variant="body" key={`wasteItem-${index}`}>
                          {item.title}
                        </Typography>
                      ))}
                  </ListStack>
                </Box>
              )}
            </Section>
          </PageGridItem>
        </PageGrid>
      </PageSection>
    </article>
  );
}
